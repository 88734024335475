import React from 'react';
import "./gpt.css";

function Gpt() {
  return (
    <div className="gpt-container">
      <iframe
        src="https://scuclub.streamlit.app/?embed=true&embed_options=light_theme"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="Clubo ChatBot"
      ></iframe>
    </div>
  );
}

export default Gpt;
